<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Seller List
                    </div>
                    <div class="card-body">
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="row search-form">
                                    <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.name" class="form-control" placeholder="Name">
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <button class="btn btn-primary mr-1 fbutton" @click="search()"><i class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-info fbutton" @click="reset()"><i class="fas fa-sync"></i> Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-light">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sortKey == key && filter.orderBy == 'desc', 'desc': filter.sortKey == key && filter.orderBy == 'asc' }">
                                                    <a href="#" @click="sortKeyBy(key)" class="text-capitalize tbl_th_a">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th >Date</th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td>{{data.en_name}}</td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.phone}}</td>
                                                <td>{{data.whatsapp}}</td>
                                                <td>{{data.address}}</td>
                                                <td>{{data.date}}</td>
                                                <td style="padding: 0px;">
                                                    <button class="btn btn-option text-primary"
                                                        @click="editData(data)" ><i class="fas fa-edit"></i></button>
                                                    <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger" ><i
                                                            class="fas fa-trash-alt" @click="deleteData(data)"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="7" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <p class="my-4 text-danger">{{alert}}</p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
        <router-link class="float" to="seller-create">
            <i class="fa fa-plus my-float" style="padding: 37%;"></i>
        </router-link>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'sellerList',
        metaInfo: {
            title: "Seller List",
            titleTemplate: "%s ← RJ Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                dataList: [],
                permissions: '',
                columns: {
                    name: "Name",
                    email: "Email",
                    phone: "Phone",
                    whatsapp: "Whatsapp",
                    address: "Address"
                },
                filter: {
                    page: 1,
                    orderBy: "asc",
                    sortKey: "created_at",
                    limit: 10,
                    name: "",
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                }
            }
        },
        watch: {
        '$route' (to, from) {
            this.fetchData()
        }
    },
        computed: {},
        methods: {
            ...mapActions({
                sellerListAction: 'sellerListAction',
                sellerDeleteAction: 'sellerDeleteAction',
            }),
            sortBy(column) {
                this.filter.sortKey = column
                if (this.filter.sortKey == column) {
                    if (this.filter.orderBy == "asc") {
                        this.filter.orderBy = "desc"
                    } else if (this.filter.orderBy == "desc") {
                        this.filter.orderBy = "asc"
                    }
                } else {
                    this.filter.orderBy = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
                this.$refs['my-modal'].hide()
            },
            async fetchData() {
                this.isLoading = true
                let option = this.filter
                await this.sellerListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({ path: 'seller-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'seller-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', name: ''} }).catch(()=>{})
                 this.$router.go()
            },
            editData(data) {
                this.$router.replace({ path: 'seller-edit', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, id: data.id, name: this.filter.name } }).catch(()=>{})
            },
            deleteData(data){
                this.id = data.id
                this.alert = ""
            },
            async deleteItem() {
				this.isLoading = true
				let option = {
					id: this.id,
				}
				await this.sellerDeleteAction({
					...option
				}).then(res => {
					if(res.status == 'fail'){
                        this.alert = res.message
                    }else{
                        this.$refs['my-modal'].hide()
                    }
                    this.fetchData()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            checkPermission: function (key) {
                if(key in this.permissions){
                    return true
                }else{
                    return false
                }
            }

        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.permissions = user.permissions
            this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
            this.filter.name = this.$route.query.name
            this.fetchData()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>